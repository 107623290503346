.Soulvanity-logo-div {
    background-image: linear-gradient(180deg, rgba(150, 150, 150, 0.5), rgba(40, 40, 40, 0.5));
    position: fixed;
    top: 0;
    left: 0;
    display: inline-grid;
    grid-template-columns: auto;
    min-width: 100%;
    min-height: 100px;
    align-items: center;
    z-index: 99;
    margin-left: 0;
    margin-right: 0;
}
.logo-link {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}
.Soulvanity-logo {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}
.Soulvanity-logo:hover {
    animation: mymove2 1s;
}
@keyframes mymove2 {
    5% {opacity : 0.0}
    5% {filter: blur(10px);}
}
@media only screen and (min-width: 768px)
{
    .logo-link {
        width: 400px;
    }
    .Soulvanity-logo {
        width: 400px;
    }
}
