.bandcamp-player {
  width: 310px;
  margin-left: auto;
  margin-right: auto;
  min-height: 150px;
}
.player-frame {
  width: 280px;
  height: 120px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
  border: 0px;
}
@media only screen and (min-width: 768px)
{
  .bandcamp-player {
    width: 560px;
  }
  .player-frame {
    width: 540px;
  }
}