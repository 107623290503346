.albumSection {
    position: relative;
    top:-30px;
    margin: 0;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    min-height: 600px;
    color: azure;
    text-shadow: 0px 4px 20px rgba(255, 255, 255, 0.6);
    /* border: 1px solid white; */
}
.albumGroup {
    margin-top: 0;
    padding-top: 60px;
    min-height: 600px;
    /* border: 1px solid white; */
    background-size: cover;
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
}
.albumCover {
    width: 300px;
    border-radius: 14px;
    box-shadow: 0px 20px 30px black;
}
.albumDetails {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}
.detailsList {
    text-align: left;
    list-style: circle;
}
#album2 {
    background-color: #000a;
    background-image: url('/src/images/endOfTimeCoverc.jpg');
    /* background-position: center -1000px; */
}
#album1 {
    background-color: #111a;
    background-image: url('/src/images/innerUnrestCoverb.jpg');
    /* background-position: -400px -1100px; */
}
#album0 {
    background-color: #444a;
    background-image: url('/src/images/demoCoverb.jpg');
    /* background-position: -200px -1000px; */
}
