.hero-section {
    margin-top: -22px;
    background-color: #aaa2;
    padding-top: 40px;
    padding-bottom: 12px;
    min-height: 60vh;
    letter-spacing: 4px;
    font-size: 24px;
    /* padding-top: 80px; */
}
.hero-section > h1 {
    text-shadow: 0px 20px 30px #000;
}
.soulvanity-logo {
    height: 200px;
}
.distro-logo {
    height: 40px;
}
.bio {
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    font-size: 16px;
    padding: 24px 18px;
    text-align: justify;
    /* border: 1px solid #aaaa; */
    border-radius: 14px;
    text-shadow: 0px 0px 10px #444;
    box-shadow: 0px 20px 30px black;
    background-color: #fff2;
}
span {
    font-style: italic;
}
.icons-container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;
    display: inline-grid;
    min-height: 140px;
    grid-template-columns: 100px 100px 100px;
    grid-row-gap: 20px;
    grid-column-gap: 40px;
    border-radius: 14px;
}

.link-icons {
    margin-left: auto;
    margin-right: auto;
    border-radius: 14px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 20px 30px black;
}
.icons {
    margin: auto;
    padding: 12px;
    width: 90px;
}
.link-icons:hover {
    transition: 0.5s;
    background-color: white;
    box-shadow: 0px 0px 30px white;
    animation: mymove2 0.5s;
}
@media only screen and (max-width: 768px)
{
    .soulvanity-logo {
        height: 70px;
    }
    .icons {
        width: 60px;
    }
    .link-icons {
        width: 70px;
    }
    .icons-container {
        grid-column-gap: 4px;
    }
}