.band-photo-div {
    position: relative;
    top: 0px;
    background-color: rgba(226, 102, 0, 0.2);
    max-width: auto;
    padding: 0px 0px 40px 0px;
    margin-left: auto;
    margin-right: auto;
    color: azure;
    letter-spacing: 2px;
    text-shadow: 0px 4px 20px rgba(255, 255, 255, 0.6);
    padding-top: 100px;
}
.thump-module {
    display: inline-flex;
    /* border: 1px solid white; */
    margin: auto;
}
.photo-module {
    /* border: 1px solid white; */
    border: none;
}
.Band-photo {
    max-width: 290px;
    border-radius: 8px;
    box-shadow: 0px 20px 30px black;
}
.Band-thump {
    margin: 4px;
    max-width: 80px;
    box-shadow: 0px 20px 30px black;
    opacity:0.8;
}
.Band-thump:hover {
    opacity: 1;
    box-shadow: 0px 0px 20px coral;
}
.displayNone {display: none;}
.displayOn {display: initial;}

@media only screen and (min-width: 668px)
{
    .band-photo-div {
        min-height: 400px;
        min-width:700px;
    }
    .Band-photo {
        max-width: 600px;
    }
    .Band-thump {
        max-width: 100px;
    }
}

@media only screen and (min-width: 920px)
{
    .band-photo-div {
        min-height: 400px;
        min-width:700px;
    }
    .Band-photo {
        max-width: 880px;
    }
    .Band-thump {
        max-width: 160px;
    }
}