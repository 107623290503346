* {
    box-sizing: border-box;
    margin: 0;
}
html {
    background-color: #0004;
}

.App {
    min-height: 10vh;
    margin: 0;
    text-align: center;
    background-image: url('images/endOfTimeCoverc.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-blend-mode: luminosity;
}
