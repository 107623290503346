.footer {
    background-image: linear-gradient(180deg, rgba(100, 100, 100, 0.7), black 90%);
    min-width: 100%;
    min-height: 40vh;
    text-align: center;
    color: white;
}

.Social-icons-div {
    display: inline-grid;
    min-height: 140px;
    grid-template-columns: 60px 60px 60px 60px 60px;
    grid-row-gap: 20px;
    grid-column-gap: 10px;
    padding-top: 30px;
}

.App-social-icons {
    display: none;
    width: 60px;
    border-radius: 14px;
    cursor: pointer;
    padding: 10px;
}

.App-social-icons:hover {
    transition: 0.5s;
    background-color: white;
    box-shadow: 0px 0px 30px white;
    animation: mymove2 0.5s;
}

.footer-par {
    margin: auto;
    letter-spacing: 2px;
    max-width: 240px;
}
@media only screen and (min-width: 768px)
{
    .Social-icons-div {
        grid-column-gap: 38px;
    }
}