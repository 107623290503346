.liveShows {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    min-height: 320px;
    padding-top: 20px;
    background-color: #8a82;
}
.liveshows-frame {
    margin-left: auto;
    margin-right: auto;
    width: 280px;
    color: rgba(255, 255, 255, 1);
    min-height: 200px;
}
.liveshows-frame > h1 {
    border-bottom-left-radius : 8px;
    border-left: 1px solid rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    padding: 16px;
}
.liveshows-frame > ul {
    letter-spacing: 2px;
    min-height: 100px;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    border-right: 1px solid rgba(255, 255, 255, 1);
    border-bottom-right-radius : 8px;
}
.list-item {
    text-align: left;
}
@media only screen and (min-width: 768px)
{
    .liveshows-frame {
        width: 700px;
    }
    .liveShows {
        width: auto;
    }
}
