.youtube-player {
  width: 310px;
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;
  color: azure;
  border: 0px;
  
}
.youtube-frame {
  width: 280px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
  border: 0px;
}
.youtube-par {
  margin-left: auto;
  margin-right: auto;
  min-height: 80px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 2px;
  text-shadow: 0px 4px 20px rgba(255, 255, 255, 0.6);
}
@media only screen and (min-width: 768px)
{
  .youtube-player {
        width: 460px;
      }
  .youtube-frame {
        width: 460px;
        height: 360px;
    }
}
